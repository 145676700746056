.main {
  width: 100%;
}

.wrapper {
  border: 1px solid gray;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
}

.editor {
  border: 1px solid rgb(229, 227, 227);
  padding: 5px 10px;
  border-radius: 10px;
}

.toolbar {
  display: none;
}

/* sidebar documents tab css */

.sub_title {
  margin-top: 35px !important;
}

.paypal_account {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* radio buttons css */

.radio_buttons.large .ant-radio-inner {
  width: 24px;
  height: 24px;
}

.radio_buttons.large .ant-radio-inner::after {
  width: 16px;
  height: 16px;
  top: 3.2px;
  left: 3px;
}

.basic-section-info-icon {
  display: flex;
  align-items: baseline;
}