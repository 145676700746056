.flex-class {
    display: flex;
    align-items: normal;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.flex-class2 {
    display: flex;
    align-items: normal;
    justify-content: center;
    gap: 10px;
}

.text-class {
    text-align: center;
    font-size: 19px;
}

.image-class {
    margin-top: 3px;
    width: 25px;
    height: 25px;
}

.image-class2 {
    width: 25px;
    height: 25px;
}

.p-class {
    margin-top: 0px !important;
}

.ant-input-group .ant-input {
    font-weight: bold;
    /* or any other font-weight value */
}

.ant-input-group .ant-input-group-addon {
    color: rgba(0, 0, 0, 0.45);
}