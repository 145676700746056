.payment_container {
  padding: 1rem;
  border: 0.1rem solid #e5e7eb;
  border-radius: 10px;
}
.payment_label_heading {
  text-transform: capitalize;
}

.secure_message {
  display: flex;
  background: #def7ec;
  font-size: 0.6rem;
  line-height: 150%;
  margin-bottom: 1rem;
  padding: 1rem 1rem;
  border-radius: 0.5rem;
}

.response_message {
  font-size: 0.6rem;
  line-height: 150%;
  margin-bottom: 1rem;
  padding: 1rem 1rem;
  border-radius: 0.5rem;
  display: flex;
  background:#f8d7da;
  border:0.1rem solid #ea5d5d;
  color:#721c24
}

.payment_bulk_wrap {
  padding: 1rem 1rem 1rem 1rem;
  border: 0.1rem solid #e3e3e3;
  border-radius: 0.5rem;
  margin-top: 1rem;
}

.card_main_div {
  display: flex;
  gap: 10px;
  /* margin-bottom: 2rem; */
}
.paypal_container {
  padding: 1rem 1rem 1rem 1rem;
  border: 0.1rem solid #e3e3e3;
  border-radius: 0.5rem;

}
.visa_container {
  padding: 1rem 1rem 1rem 1rem;
  border: 0.1rem solid #e3e3e3;
  border-radius: 0.5rem;
  margin-top: 1rem;
}
.paypal_main_div {
  display: flex;
  gap: 10px;
}

.paypal_main_btn {
  margin-top: 2rem;
}
@media (max-width:768px) {
  .payment_container{
    padding: 0.4rem;
  }
  .paypal_container{
    padding: 0.5rem;
  }
  .payment_bulk_wrap{
    padding: 0.5rem;
  }
  .visa_container{
    padding: 1rem 0.4rem 0 ;
  }
}

/* recurring payments page classes */
.table_header_recurring_payments {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0;
  border-bottom: 2px solid #95bd51;
}

.table-cell_recurring_payments {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.are_you_sure_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.are_you_sure_modal_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.dropdown-button {
  border-radius: 10px;
  border: 1px solid black;
  font-weight: bold;
  padding: 10px;
  max-width: 170px;
  background: none;
  width: 100%;
  color: black;
  height: 56px;
  font-size: 20px;

  &:hover {
    background-color: none; 
    color: black;
  }
  &:focus {
    background-color: none; 
    color: black; 
  }

  &:visited {
    background-color: none; 
    color: black; 
  }
}

@media screen and (max-width: 768px) {
  .dropdown-button {
    font-size: 16px;
    max-width: 135px;
    width: 100%;
  }
}
.custom-tooltip {
  min-width: 750px !important;
  width: 750px !important; 
  line-height: 1.5;
  text-align: justify;
}

@media screen and (max-width: 768px) {
  .custom-tooltip {
    min-width: 200px !important;
    width: 200px !important; 
  }
}

.mobile_header {
  font-size: 14px;
  color: #95bd51;
}