.active-category {
  background-color: #95bd51;
  padding: 0 20px !important;
  font-weight: bold;
  color: white !important;
  border-radius: 5px;
  /* margin-left: 20px; */
  margin-left: -15px;
  margin-top: 10px;
}

.category:hover,
.category:active {
  background-color: #95bd51;
  padding: 0 20px !important;
  font-weight: bold;
  color: white !important;
  border-radius: 5px;
  /* margin-left: 20px; */
  margin-left: -15px;
  margin-top: 10px;
}

/* .active-category::before {
  content: none !important;
} */

/* .previous::before {
  content: none !important;
} */
