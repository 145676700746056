.project_footer_wrapper {
  background: #fff;
  box-shadow: 0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%),
    0 8px 10px -5px rgb(0 0 0 / 20%);
  border-radius: 12px;
  /* margin-top: 30px; */
  margin-bottom: 30px;
  padding: 20px 14px;
  width: 100%;
}

.collected_funds {
  font-size: 30px;
  font-weight: 700;
}
.ant-select-selection-search {
  width: 100% !important;
}
.raised_amount {
  font-size: 20px;
  color: #767676;
}

.parent_funds {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.btn_main {
  flex-direction: column;
}

.btn_main button {
  width: 100%;
}

.funds_summary {
  display: flex;
  gap: 10px;
}

.rewards_text {
  font-size: 16px !important;
  white-space: normal;
}

.project-rewards-main {
  text-align: start !important;
}

.donars_count {
  font-size: 20px;
  color: #6d8083;
  font-weight: 600;
}

.description-line {
  font-size: 16px !important;
  /* padding: 20px 0px; */
  /* text-transform: capitalize; */
  line-height: 1.6 !important;
}

.line_hight {
  height: 1px;
  background-color: #cfcfcf;
  margin-top: 10px;
  margin-bottom: 10px;
}

.project_detail_heading_lg {
  display: block;
}

.project_detail_heading_sm {
  display: none !important;
}

.progress_bar_lg {
  display: block;
}

.progress_bar_sm {
  display: none !important;
}

.total_donors {
  display: none;
}

.project_details_btn {
  display: flex;
  flex-direction: row !important;
  gap: 20px !important;
}

.project_info_desc_mobile {
  display: none !important;
}

.project_info_desc_lg {
  display: block !important;
}

.funds_sm {
  display: none !important;
}

.funds_lg {
  display: block !important;
}

.icons_detail_section {
  display: flex !important;
  justify-content: space-between !important;
}

.icon_main_div {
  display: flex !important;
  margin-top: 15px !important;
}

.icon_main_div_supporters {
  display: flex !important;
  justify-content: center;
  gap: 5px;
  margin-top: 0px !important;
}

.detail_count {
  margin-left: 7px !important;
}

.detail_p {
  font-size: 14px !important;
  margin-bottom: 0px !important;
  line-height: 10px !important;
  font-weight: bold !important;
  color: black !important;
  text-align: center !important;
}
.detail_p_text {
  font-size: 13px !important;
  font-weight: 300 !important;
  /* line-height: 1 !important; */
  opacity: 0.7 !important;
  color: #22312b;
}

.city_p_text {
  font-size: 13px !important;
  font-weight: 300 !important;
  /* line-height: 1 !important; */
  opacity: 0.7 !important;
  color: #22312b;
  max-width: 110px;
  text-align: center;
  margin-top: 9px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.project-rewards-gift:hover {
  color: #95bd51 !important;
  cursor: pointer !important;
}
.project-rewards-inner:hover {
  color: #95bd51;
  cursor: pointer;
}
.seprator-lg {
  border-bottom: 2px solid #95bd51 !important;
  margin-bottom: 10px;
}

.seprator-sm {
  display: none;
}

.title_subtitile {
  padding-left: 10px;
}

._sofortIcons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 2px solid #d9d9d9;
  background-color: #fff;
  border-radius: 6px;
  padding: 5px;
  padding-bottom: 0;
}
._sofortIcons svg {
  width: 100%;
  height: 100%;
}
.No_Support_content {
  white-space: break-spaces;
  text-align: center;
  height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 768px) {
  .project_footer_wrapper {
    box-shadow: none !important;
    width: 100% !important;
    margin-bottom: 25px !important;
    margin-bottom: 0px !important;
    padding: 5px 10px;
  }

  .description-line {
    font-size: 16px !important;
    padding: 5px 0px !important;
  }

  /* .content-Wrapper {
    width: 1120px !important;
  } */

  .project_detail_heading_lg {
    display: none !important;
  }

  .project_detail_heading_sm {
    display: block !important;
  }

  .progress_bar_lg {
    display: none !important;
  }

  .progress_bar_sm {
    display: block !important;
  }

  .raised_amount {
    font-size: 14px !important;
  }

  .collected_funds {
    font-size: 16px !important;
  }

  .circle {
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background-color: #c8c8c8 !important;
  }

  .total_donors {
    font-size: 16px !important;
    display: block;
    font-weight: 700;
  }
  /* 
  .donars_count {
    display: none !important;

  } */

  .project_details_btn {
    flex-direction: column !important;
    gap: 0px !important;
  }

  .project-info__funding {
    padding: 0px 10px !important;
  }

  .project-rewards-main {
    text-align: start !important;
    margin-left: 4px !important;
  }

  .project_info_desc_mobile {
    display: block !important;
    padding: 0px 10px !important;
    font-size: 18px !important;
  }

  .project_info_desc_lg {
    display: none !important;
  }

  .funds_lg {
    display: none !important;
  }

  .funds_sm {
    display: flex !important;
    text-align: left !important;
    flex-direction: row !important;
    margin: 5px 0px 35px 0px !important;
  }
  .seprator-sm {
    display: block !important;
    border-bottom: 2px solid #95bd51 !important;
    margin-top: 10px;
  }
  .seprator-lg {
    margin-top: 10px;
    display: none;
  }
  .donation-note {
    margin-left: 4px;
  }
  .title_subtitile {
    padding-left: 0px;
  }
  .goodies_initiator {
    padding: 0px 7px !important;
  }
  ._sofortIcons {
    border-width: 1px;
    padding: 2px;
    padding-bottom: 0;
  }
  .No_Support_content {
    height: unset !important;
    margin: 15px 0px;
  }
  .rewards_text {
    font-size: 15px !important;
    white-space: nowrap !important;
  }
}

textarea.ant-input:focus {
  box-shadow: none;
}

.text_area {
  font-family: 'Helvetica', 'Roboto', Arial, sans-serif;
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  word-break: normal;
  padding-left: 0;
}

/* viewProject page css */

.text_area_view_project {
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  padding: 0 !important;
}

.remark {
  color: #00000073;
  font-size: 14px !important;
}

.txt {
  font-style: italic;
  font-family: 'Helvetica', 'Roboto', Arial, sans-serif !important;
}
.w-full {
  width: 100%;
}
.insta_patreon {
  justify-content: center;
  align-items: end;
  margin-bottom: 15px !important;
}
.insta_patreon .detail_p_text_patreon {
  margin: 0;
}

.insta-handler:hover {
  color: #95bd51;
}

.detail_p_text_patreon {
  font-size: 13px !important;
  font-weight: 300 !important;
  color: #22312b !important;
}

.project-detail__hero {
  /* display: flex;
  gap: 20px; */
}

@media (max-width: 992px) {
  .project-detail__hero {
    /* flex-direction: column; */
  }
}
.rit {
  float: right;
}
