.form-item-visible {
  display: flex;
  align-items: baseline;
  gap: 3rem;
  flex-direction: row !important;
}

.categorybanner .img-wrapper img {
  height: auto !important;
  min-height: auto !important;
  max-height: 410px !important;
}

@media screen and (max-width: 580px) {
  .categorybanner .img-wrapper img {
    height: 152px !important;
    min-height: auto !important;
    max-height: 410px !important;
  }
}
.categorybanner #banner {
  height: auto !important;
  min-height: auto !important;
  max-height: 410px !important;
}

.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #95bd51 !important;
  border-color: #95bd51 !important;
}
.ant-radio.ant-radio-checked .ant-radio-inner {
  border-color: #95bd51 !important;
}
.ant-radio-checked .ant-radio-inner::after {
  background-color: #95bd51 !important;
  border-radius: 50%;
}

.ant-switch-checked {
  background-color: #95bd51 !important;
}

.mt-4 {
  margin-top: 2rem;
}
