.card a:hover {
  text-decoration: none;
  color: #1e322b;
}
.card__description {
  text-decoration: none;
  color: #1e322b !important;
}
.projects-slider .carousel-container {
  display: flex !important;
  align-items: stretch !important;
}

.projects-slider .slick-slide > div {
  height: 100%;
}
/* .big-projects-slider .slick-slide {
  padding: 10px 20px;
} */
.donation-table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 10px;
  font-family: Arial, sans-serif;
  margin-bottom: 40px;
}
.donation-table th,
.donation-table td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}
.donation-table th {
  background-color: #f6f6f6;
  font-weight: bold;
}
.donation-table tr:nth-child(even) {
  background-color: #f6f6f6;
}
.donation-table caption {
  caption-side: top;
  font-weight: bold;
  padding-bottom: 5px;
}

.google-login-button {
  background-color: #4285f4; /* Google blue */
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-family: Arial, sans-serif;
  font-weight: bold;
}

.google-login-button:hover {
  background-color: #3367d6; /* Darker blue on hover */
}

.google-login-icon {
  height: 20px;
  width: 20px;
}

@media screen and (max-width: 600px) {
  .slick-slide,
  .slick-slide > div > div,
  .slick-track {
    height: 100% !important;
  }
}

.truncate-3-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}
.ant-popover-content {
  max-height: 100vh;
  overflow-y: auto;
  margin-right: 20px;
  border-radius: 12px;
}
.ant-popover-inner {
  margin-top: 80px;
  border-radius: 12px;
}

.ant-popover-arrow {
  display: none;
}
.ant-popover-inner-content {
  margin-bottom: 5px;
  width: 600px;
  padding-bottom: 5px !important;
}
