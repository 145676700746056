.content_container {
    max-width: 600px;
    width: 90%;
    margin: 2rem auto;
    padding: 0 20px;
    text-align: center;
    position: relative;
    box-sizing: border-box;
  }

  .corner_top_left {
    position: absolute;
    top: -10px;
    left: -10px;
    width: 30px;
    height: 30px;
    border-top: 6px solid #dab44d;
    border-left: 6px solid #dab44d;
  }

  .corner_bottom_right {
    position: absolute;
    bottom: -10px;
    right: -10px;
    width: 30px;
    height: 30px;
    border-bottom: 6px solid #dab44d;
    border-right: 6px solid #dab44d;
  }

  .main_heading {
    font-size: clamp(20px, 6vw, 24px);
    font-weight: 700;
    margin: 0;
  }

  .main_description {
    font-size: clamp(14px, 4vw, 16px);
    font-weight: 400;
    color: #555;
    margin: 1rem 0;
  }

  .step_container {
    text-align: left;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .step_icon {
    width: clamp(32px, 10vw, 46px);
    height: clamp(32px, 10vw, 46px);
    flex-shrink: 0;
  }
  .vector_icon{
    width: clamp(18px, 10vw, 18x);
    height: clamp(18px, 10vw, 18px);
    flex-shrink: 0;
  }

  .step_heading {
    font-size: clamp(18px, 5vw, 24px);
    font-weight: 700;
    margin: 6px;
  }

  .step_description {
    font-size: clamp(14px, 4vw, 16px);
    font-weight: 400;
    color: #555;
    margin-left: 8px;
  }

  .button_container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .bottom_cta {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 20px;
    background-color: #dab44d;
    color: white;
    padding: 10px 20px;
    font-size: clamp(16px, 5vw, 18px);
    font-weight: 700;
    border-radius: 5px;
    text-decoration: none;
    box-sizing: border-box;
    max-width: 300px;
    white-space: nowrap;
    border-style: none;
  }

  .arrow_icon {
    width: 20px;
    height: 20px;
  }